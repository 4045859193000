import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import classnames from "classnames";
import { get } from "lodash";
import LoBug from "../LoBug";
import AccessibilityLink from "../AccessibilityLink";
import {
    showAccessibilityLink,
    accessiblityLinkSites,
} from "../../lib/helpers";
import Footer from "./components/Footer";
import OneTrustScript from "../OneTrustScript";
import { useInView } from "react-intersection-observer";

import "../shared/styles.less"; // site-wide styles
import styles from "./styles.module.less"; // layout-only styles

const ucLoader =
    typeof window !== "undefined" ? require("../../lib/ucLoader") : null;

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            jobTitle: "",
            licenseName: "",
            licenseId: "",
            photo: "",
            applyNow: "",
            applyNowScript: "",
            logIn: "",
            slug: "",
            show: "",
        };
    }
    static defaultProps = {
        header: null, // header here is the <Header /> component, configured and injected for a given page
        hasHero: false,
        headBeginScript: [],
        headEndScript: [],
        bodyBeginScript: [],
        bodyEndScript: [],
    };
    showLo = () => {
        if (this.props.setBug) {
            localStorage.setItem("show", "true");
        }
    };
    onClickClose = () => {
        localStorage.setItem("show", "false");
        this.setState({
            show: "false",
        });
    };

    saveData = () => {
        if (!!this.props.loData) {
            localStorage.setItem("name", this.props.loData.name);
            localStorage.setItem("jobTitle", this.props.loData.jobTitle);
            localStorage.setItem(
                "licenseName",
                get(this.props.loData, "licenseIds[0].license", ""),
            );
            localStorage.setItem(
                "licenseId",
                get(this.props.loData, "licenseIds[0].id", ""),
            );
            localStorage.setItem("photo", get(this.props, "loPhoto.photo", ""));
            localStorage.setItem(
                "applyNow",
                get(this.props, "loData.applyNow", ""),
            );
            localStorage.setItem(
                "applyNowScript",
                get(this.props, "loData.applyNowScript", ""),
            );

            localStorage.setItem("logIn", this.props.loData.logIn || "");
            localStorage.setItem("slug", this.props.loData.slug);
        } else if (!!this.props.teamData) {
            localStorage.setItem("name", this.props.teamData.name);
            localStorage.setItem("jobTitle", "");
            localStorage.setItem(
                "licenseName",
                get(this.props.loData, "licenseIds[0].license", ""),
            );
            localStorage.setItem(
                "licenseId",
                get(this.props.loData, "licenseIds[0].id", ""),
            );
            localStorage.setItem(
                "applyNow",
                this.props.teamData.applyNow || "",
            );
            localStorage.setItem(
                "applyNowScript",
                this.props.teamData.applyNowScript || "",
            );
            localStorage.setItem("photo", this.props.teamData.logo);
            localStorage.setItem("logIn", this.props.teamData.logIn || "");
            localStorage.setItem("slug", this.props.teamData.slug);
        }
    };

    setData = () => {
        this.setState({
            show: localStorage.getItem("show"),
            name: localStorage.getItem("name"),
            jobTitle: localStorage.getItem("jobTitle"),
            licenseName: localStorage.getItem("licenseName"),
            licenseId: localStorage.getItem("licenseId"),
            photo: localStorage.getItem("photo"),
            applyNow: localStorage.getItem("applyNow"),
            applyNowScript: localStorage.getItem("applyNowScript"),
            logIn: localStorage.getItem("logIn"),
            slug: localStorage.getItem("slug"),
        });
    };

    componentDidMount() {
        this.showLo();
        this.saveData();
        this.setData();
    }

    renderHeadScripts(scripts = []) {
        return (
            <Helmet>
                {scripts
                    .map((el, i) => {
                        const { name, html, async, src } = el;
                        if (name === "script") {
                            if (src) {
                                return (
                                    <script key={i} src={src} async={async} />
                                );
                            } else {
                                return (
                                    <script key={i} async={async}>
                                        {html}
                                    </script>
                                );
                            }
                        }
                        if (name === "noscript") {
                            return <noscript key={i}>{html}</noscript>;
                        }
                        return null;
                    })
                    .filter(el => !!el)}
            </Helmet>
        );
    }

    renderBodyScripts(scripts = []) {
        return (
            <>
                {scripts
                    .map((el, i) => {
                        const { name, html, ...rest } = el;
                        if (el.name === "script") {
                            return (
                                <script
                                    key={i}
                                    {...rest}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            );
                        }
                        if (el.name === "noscript") {
                            return (
                                <noscript
                                    key={i}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            );
                        }
                        return null;
                    })
                    .filter(el => !!el)}
            </>
        );
    }

    render() {
        const {
            children,
            hasHero,
            header,
            headBeginScript,
            headEndScript,
            bodyBeginScript,
            bodyEndScript,
        } = this.props;
        let cls = classnames(styles.Layout, { [styles.hero]: !!hasHero });
        const {
            name,
            jobTitle,
            licenseName,
            licenseId,
            photo,
            applyNow,
            applyNowScript,
            logIn,
            slug,
            show,
        } = this.state;
        let showBug;
        if (show === "false") {
            showBug = false;
        } else {
            showBug = true;
        }
        const showLink = showAccessibilityLink(accessiblityLinkSites.CMS);
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                                description
                            }
                        }
                    }
                `}
                render={data => {
                    const { ref, inView } = useInView();
                    return (
                        <>
                            <OneTrustScript />
                            {this.renderHeadScripts(headBeginScript)}
                            {this.renderBodyScripts(bodyBeginScript)}
                            <Helmet
                                title={data.site.siteMetadata.title}
                                meta={[
                                    {
                                        name: "description",
                                        content:
                                            data.site.siteMetadata.description,
                                    },
                                ]}>
                                <html lang="en" />
                                <style>
                                    @import
                                    url('https://fonts.googleapis.com/css?family=Quattrocento');
                                </style>
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: ucLoader,
                                    }}
                                />
                            </Helmet>
                            {showLink && <AccessibilityLink />}
                            {header}
                            {show && (
                                <LoBug
                                    show={showBug}
                                    name={name}
                                    jobTitle={jobTitle}
                                    licenseName={licenseName}
                                    licenseId={licenseId}
                                    photo={photo}
                                    slug={slug}
                                    applyNow={applyNow}
                                    applyNowScript={applyNowScript}
                                    logIn={logIn}
                                    onClick={this.onClickClose}
                                    applyOffset={inView}
                                />
                            )}

                            <div className={cls}>{children}</div>
                            <Footer
                                staticTextItems={this.props.staticTextItems}
                                buttonRef={ref}
                            />
                            {this.renderBodyScripts(bodyEndScript)}
                            {this.renderHeadScripts(headEndScript)}
                        </>
                    );
                }}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
